<template>
  <div v-if="apiToken && ReportDetails" class="vasion-calc-height vasion-white-background">
    <div class="innerDiv">
      <div class="md-layout headerDiv">
        <div class="md-layout-item vasion-page-title">
          Custom Report Configuration
        </div>
        <div class="md-layout-item alignRight">
          <VasionButton id="btnCreateReport" :classProp="'primary'" @vasionButtonClicked="selectReport(0)">
            New Report
          </VasionButton>
        </div>
      </div>
      <div class="gridDiv vasion-html-table-default">
        <table>
          <thead>
            <tr>
              <th>
                <label class="vasion-html-table-header">Report Name</label>
              </th>
              <th class="controls-column">
                <label class="vasion-html-table-header" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(report, index) in ReportDetails"
              :key="index"
              :class="{'whiteBackground': index % 2 === 0}"
            >
              <td>
                <label class="vasion-html-table-field">{{ report.ReportName }}</label>
              </td>
              <td class="controls-column">
                <div class="md-layout">
                  <div>
                    <VasionButton
                      id="btnCreateReport"
                      title="Edit Report"
                      :isDense="true"
                      :icon="'edit'"
                      @vasionButtonClicked="selectReport(report.ReportID)"
                    />
                  </div>
                  <div>
                    <VasionButton
                      title="Remove Report"
                      :isDense="true"
                      :icon="'delete'"
                      @vasionButtonClicked="deleteReport(report.ReportID)"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheAdminCustomReportConfig',
  components: {
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    ReportDetails() { return this.$store.state.reporting.adminCustomReports },
  },
  created: function () {
    this.$store.dispatch('reporting/loadAllCustomReportDetails');
  },
  methods: {
    deleteReport: function (reportID) { this.$store.dispatch('reporting/deleteCustomReport', reportID); },
    selectReport: function (reportID) {
      this.$store.dispatch('reporting/setSelectedReportConfig', reportID);
      this.$router.push({ name: 'TheReportConfig' }) // Todo: Not sure where this goes this route doesn't exist
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .headerDiv {
    width: 100%;
  }

  .innerDiv {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .gridDiv {
    height: calc(100vh - 140px);
    overflow: auto;
  }

  .whiteBackground {
    background-color: $grey-50;
  }

  .alignRight {
    text-align: right;
  }

  .controls-column {
    width: 75px;
  }

</style>
